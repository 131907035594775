import React from 'react';
import { useTheme, useMediaQuery, Box, Typography, IconButton, Link } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Grid from '@mui/material/Grid';
import profileImage from "../../images/profile_image.jpg";

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const iconStyle = {
    fontSize: '34px',
  };

  const profileImageContainerStyle = {
    width: '250px',
    height: '250px',
    borderRadius: '10px',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const containerStyle = {
    marginTop: '80px', 
    marginBottom: '20px',
  };

  const centeredContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center', 
    height: '100%',
  };

  const textContainerStyle = {
    padding: isMobile ? '0 30px' : '0 2px', 
    height: '100%', 
  };

  return (
    <Box style={containerStyle}>
      <Grid container direction={isMobile ? 'column' : 'row'} spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Box style={centeredContainerStyle}>
            <div style={profileImageContainerStyle}>
              <img src={profileImage} alt="Your Image" style={imageStyle} />
            </div>
            <Typography variant="h4" gutterBottom style={{ paddingTop: '10px' }}>
              Luis Gaspar Schroeder
            </Typography>
            <Grid container direction="row" spacing={isMobile ? 1 : 2} justifyContent="center">
              <Grid item xs={6} sm={6} md={4}>
                <IconButton color="primary" aria-label="GitHub" href="https://github.com/luis-gasparschroeder" target="_blank" rel="noopener noreferrer">
                  <GitHubIcon style={iconStyle} />
                </IconButton>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <IconButton color="primary" aria-label="LinkedIn" href="https://www.linkedin.com/in/luis-gaspar-schroeder/" target="_blank" rel="noopener noreferrer">
                  <LinkedInIcon style={iconStyle} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box style={textContainerStyle}>
          <Typography variant="body1" textAlign={'justify'}>
            I am a Computer Science graduate student at the <strong><Link href="https://www.tum.de" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Technical University of Munich</Link></strong> and <strong><Link href="https://www.berkeley.edu/" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>UC Berkeley</Link></strong>, conducting research at the <strong><Link href="https://sky.cs.berkeley.edu/" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Sky Lab</Link></strong>. 
            My research focuses on the intersection between distributed computer systems, databases, and ML Systems. Currently, I am working on semantic prompt caches. My earlier work targeted database connectors, query optimization, and cloud system monitoring. 
            My undergrad was in Computer Science at the <strong><Link href="https://www.tum.de" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Technical University of Munich</Link></strong>, where I researched system security supervised by <strong><Link href="https://www.sec.in.tum.de/i20/people/claudia-eckert" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Prof. Claudia Eckert</Link></strong>. Furthermore, I researched at the <strong><Link href="https://www.fraunhofer.de" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Fraunhofer Institute for Applied and Integrated Security</Link></strong>, where I contributed to the cloud system monitoring project <strong><Link href="https://www.aisec.fraunhofer.de/en/fields-of-expertise/SAS/Clouditor.html" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Clouditor</Link></strong>.
            Before interning at <strong><Link href="https://www.microsoft.com" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Microsoft</Link></strong>, I interned at <strong><Link href="https://www.snowflake.com" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>Snowflake</Link></strong>, the <strong><Link href="https://www.munichre.com" target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>MunichRe</Link></strong>, multiple other companies, and co-founded a software company during high school.
          </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
