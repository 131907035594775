import React from 'react';
import Section from '../common/section'

import aisecLogo from "../../images/logos/aisec.jpg";
import tumLogo from "../../images/logos/tum.jpg";
import mirmiLogo from "../../images/logos/mirmi.jpg";
import bachelorThesisDoc from "../../documents/bachelor_thesis.pdf";
import mirmiDoc from "../../documents/optimal_control_legged_robots.pdf";

const experiences = [
  {
    name: "Adaptive Semantic Prompt Caching with VectorQ",
    title: "Under Submission",
    location: "Luis Gaspar Schroeder, Shu Liu, Alejandro Cuadron, Mark Zhao, Stephan Krusche, Alfons Kemper, Matei Zaharia, Joseph E. Gonzalez",
    description: "",
  },
  {
    name: "The Danger of Overthinking: Failure of Reasoning Models on SWE-Bench",
    title: "Under Submission",
    location: "Alejandro Cuadron, Dacheng Li, Wenjie Ma, Xingyao Wang, Yichuan Wang, Siyuan Zhuang, Luis Gaspar Schroeder, Tian Xia, Huanzhi Mao, Shu Liu, Nicholas Thumiger, Aditya Desai, Ion Stoica, Ana Klimovic, Graham Neubig, Joseph E. Gonzalez",
    description: "",
  },
  {
    name: "Optimizing LLM Queries in Relational Data Analytics Workloads",
    title: "MLSys 2025",
    location: "Shu Liu, Asim Biswal, Audrey Cheng, Amog Kamsetty, Luis Gaspar Schroeder, Liana Patel, Shiyi Cao, Xiangxi Mo, Ion Stoica, Joseph E. Gonzalez, Matei Zaharia",
    description: "",
  },
];

const Research = () => {
  return <Section sectionTitle="Publications" data={experiences} styleOverrides={{ minHeight: '180px' }} />;
};

export default Research;
