import React from 'react';
import Section from '../common/section'

import aisecLogo from "../../images/logos/aisec.jpg";
import tumLogo from "../../images/logos/tum.jpg";
import mirmiLogo from "../../images/logos/mirmi.jpg";
import bachelorThesisDoc from "../../documents/bachelor_thesis.pdf";
import mirmiDoc from "../../documents/optimal_control_legged_robots.pdf";

const experiences = [
  {
    name: "Sky Lab",
    title: "Visiting Student",
    period: "09/2024 - present",
    location: "Berkeley, United States",
    description: "Researching semantic prompt caches and online nearest neighbor classifiers.",
    imageUrl: tumLogo,
  },
  {
    name: "Chair of IT Security (TU Munich)",
    title: "Bachelor's Thesis",
    period: "05/2023 - 09/2023",
    location: "Munich, Germany",
    description: "Researched the adaptation of Runtime Attestation Driven Development (RADD) to enhance security in software systems, focusing on its application in different operating systems and through the manipulation of data structures.",
    imageUrl: tumLogo,
  },
  {
    name: "Fraunhofer Institute (AISEC)",
    title: "Research Assistant",
    period: "03/2023 - 09/2023",
    location: "Munich, Germany",
    description: "Researched metrics to evaluate the compliance of cloud systems. Contributed to the development Clouditor, a continuous cloud security tool.",
    imageUrl: aisecLogo
  },
  {
    name: "Munich Institute of Robotics and Machine Intelligence (MIRMI)",
    title: "Research Seminar",
    period: "07/2022 - 09/2022",
    location: "Munich, Germany",
    description: "Researched optimal control strategies for legged robots, focusing on enhancing their stability and agility to match their biological counterparts. The research focuses on the development of real-time control solutions that address the computational complexity of advanced locomotion of legged robots.",
    imageUrl: mirmiLogo,
  }
];

const Research = () => {
  return <Section sectionTitle="Research Experience" data={experiences} styleOverrides={{ minHeight: '180px' }} />;
};

export default Research;
