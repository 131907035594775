import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import privacyPolicyDoc from "../../documents/privacy_policy.pdf";

function Footer() {
  const openPrivacyPolicy = () => {
    window.open(privacyPolicyDoc, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box style={{ padding: '20px', textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="body2">Reach out to me at "luisgasparschroeder[at]berkeley[dot]edu"</Typography>
      <Typography variant="body2">Last updated: January 2025</Typography>
      <Box display="flex" justifyContent="center" marginTop="10px">
        <Link
          component="button"
          variant="body2"
          onClick={openPrivacyPolicy}
        >
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;
